import { Component, HostListener } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { Platform, AlertController } from '@ionic/angular';
import { LanguageService } from './services/language.service';
import { ScreensizeService } from './services/screensize.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

  language;

  constructor(
    private swUpdate: SwUpdate,
    private screensizeService: ScreensizeService,
    private platform: Platform,
    private languageService: LanguageService,
    private alertController: AlertController,
    ) {
    this.initializeApp();
    this.languageService.setInitialAppLanguage();
    /* this.languageService.getLanguageFromDevice(); */
  }

  @HostListener('window:resize', ['$event'])
  private onResize(event) {
    this.screensizeService.onResize(event.target.innerWidth);
  }

  initializeApp(): void {
    if (this.swUpdate.available) {
      this.swUpdate.available.subscribe(() => {
        if (confirm('A new version is available. Load it?')) {
          window.location.reload();
        }
      });
    }
    this.platform.ready().then(() => {
      this.screensizeService.onResize(this.platform.width());
    });
  }

  public appPages = [
    { title: 'Home', url: '/explore', icon: 'home' },
    { title: 'Map', url: '/members', icon: 'map' },
    { title: 'Events', url: '/events', icon: 'ticket' }
  ];


  async presentAlertRadio() {
    this.language = this.languageService.getCurrentLanguage();

    const alert = await this.alertController.create({
      cssClass: '',
      header: 'Change Language',
      inputs: [
        {
          name: 'english',
          type: 'radio',
          label: 'English',
          value: 'en',
          checked: this.language === 'en',
          handler: () => {
            this.language = 'en';
          }
        },
        {
          name: 'finnish',
          type: 'radio',
          label: 'Finnish',
          value: 'fi',
          checked: this.language === 'fi',
          handler: () => {
            this.language = 'fi';
          }
        }
      ],
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            console.log('Language change canceled');
          }
        }, {
          text: 'Confirm',
          handler: async() => {
            await this.changeLanguage(this.language);
            this.presentAlert();
          }
        }
      ]
    });

    await alert.present();
  }

  async changeLanguage(value) {
    this.languageService.setLanguage(value);
  }

  async presentAlert() {
    const alert = await this.alertController.create({
      header: 'Language changed.',
      subHeader: 'We need to refresh the page to make sure all the data is translated.',
      message: '',
      buttons: [
        {
          text: 'OK',
          handler: () => {
            window.location.reload();
          }
        }
      ]
    });
    await alert.present();
  }



}
