import { NavController, Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Storage } from '@ionic/storage-angular';

const DDH_LNG_KEY = 'SELECTED_LANGUAGE';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  selected = '';

  constructor(private translate: TranslateService, private plt: Platform, private router: Router, private navController: NavController, private storage: Storage) { }

  async setInitialAppLanguage() {
    this.translate.setDefaultLang('en');

    await this.storage.create();

    await this.storage.get(DDH_LNG_KEY).then(val => {
      if (val) {
        this.setLanguage(val);
        this.selected = val;
      } else {
        this.getLanguageFromDevice();
      }
    });
  }

  getCurrentLanguage() {
    return this.translate.currentLang;
  }

  getLanguageFromDevice() {
    /* this.translate.use('en'); */
    if (window.Intl && typeof window.Intl === 'object') {
      if (navigator.language === 'fi-FI' || navigator.language === 'fi' || navigator.language === 'fi-fi') {
        this.translate.use('fi');
      } else {
        this.translate.use('en');
      }
    } else {
      this.translate.use('en');
    }
  }

  setLanguage(lng) {
    this.translate.use(lng);
    this.storage.set(DDH_LNG_KEY, lng);
  }

}
